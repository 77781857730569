// export const apiUrl = process.env.REACT_APP_API_URL || 'https://35.171.220.209:8000/v1/';
export const apiUrl = process.env.REACT_APP_API_URL || 'https://api.innerworkout.app:8000/v1/';
export const DashboardAPIUrl = `${apiUrl}home/dashboard`;
export const LoginAPI = `${apiUrl}auth/login`;
export const ResetPinAPI = `${apiUrl}auth/reset-pin`;
export const ResetPasswordAPI = `${apiUrl}auth/reset-password`;
export const createJourney = `${apiUrl}journey`;
export const deleteJourney = `${apiUrl}journey`;
export const updateJourney = `${apiUrl}journey`;
export const createQuizJourney = `${apiUrl}onBoardQuiz`;
export const deleteQuizJourney = `${apiUrl}onBoardQuiz`;
export const updateQuizJourney = `${apiUrl}onBoardQuiz`;
export const createJourneyDay = `${apiUrl}journeyDay`;
export const updateJourneyDay = `${apiUrl}journeyDay`;
export const deleteJourneyDay = `${apiUrl}journeyDay`;
export const createJourneyDayQuestion = `${apiUrl}journeyQuestion`;
export const updateJourneyDayQuestion = `${apiUrl}journeyQuestion`;
export const deleteJourneyDayQuestion = `${apiUrl}journeyQuestion`;
export const fetchJourney = `${apiUrl}journey`;
export const fetchJourneyAll = `${apiUrl}journey/all`;
export const fetchQuizJourney = `${apiUrl}onBoardQuiz`;
export const fetchJourneyDay = `${apiUrl}journeyDay`;
export const fetchJourneyDayQuestion = `${apiUrl}journeyQuestion`;
export const createReflection = `${apiUrl}reflections`;
export const createReflectionQuestion = `${apiUrl}reflectionQuestion`;
export const updateReflectionQuestion = `${apiUrl}reflectionQuestion`;
export const deleteReflection = `${apiUrl}reflections`;
export const createReflectionDay = `${apiUrl}reflectionsDay`;
export const deleteReflectionDay = `${apiUrl}reflectionsDay`;
export const createReflectionDayQuestion = `${apiUrl}reflectionsQuestion`;
export const deleteReflectionDayQuestion = `${apiUrl}reflectionQuestion`;
export const fetchReflection = `${apiUrl}reflections`;
export const fetchReflectionDay = `${apiUrl}reflectionsDay`;
export const fetchReflectionDayQuestion = `${apiUrl}reflectionsQuestion`;
export const createAffirmation = `${apiUrl}affirmation`;
export const createAffirmationQuestion = `${apiUrl}reflectionQuestion`;
export const deleteAffirmation = `${apiUrl}reflections`;
export const createAffirmationDay = `${apiUrl}reflectionsDay`;
export const deleteAffirmationDay = `${apiUrl}reflectionsDay`;
export const createAffirmationDayQuestion = `${apiUrl}reflectionsQuestion`;
export const deleteAffirmationayQuestion = `${apiUrl}reflectionsQuestion`;
export const fetchAffirmation= `${apiUrl}affirmation`;
export const customerSupport= `${apiUrl}customerSupport`;
export const fetchAffirmationDay = `${apiUrl}reflectionsDay`;
export const fetchAffirmationDayQuestion = `${apiUrl}reflectionsQuestion`;
export const fetchAllUsers = `${apiUrl}users`;
export const deleteUser = `${apiUrl}users`;
export const createMeditation = `${apiUrl}meditation`;
export const fetchMeditation = `${apiUrl}meditation`;
export const updateMeditationData = `${apiUrl}meditation`;
export const deleteMeditationApi = `${apiUrl}meditation`;
export const createAudioAffirmation = `${apiUrl}audioAffirmation`;
export const fetchAudioAffirmation = `${apiUrl}audioAffirmation`;
export const updateAudioAffirmationData = `${apiUrl}audioAffirmation`;
export const deleteAudioAffirmationApi = `${apiUrl}audioAffirmation`;
export const createBreathwork = `${apiUrl}breathwork`;
export const fetchBreathwork = `${apiUrl}breathwork`;
export const updateBreathworkData = `${apiUrl}breathwork`;
export const deleteBreathworkApi = `${apiUrl}breathwork`;
export const createExplore = `${apiUrl}explore`;
export const fetchExplore = `${apiUrl}explore`;
export const updateExploreData = `${apiUrl}explore`;
export const deleteExploreApi = `${apiUrl}explore`;
export const sentCustomNotification = `${apiUrl}customNotification`;
export const getNotification = `${apiUrl}customNotification/fetchNotification`;
export const updateNotification = `${apiUrl}customNotification/updatenotification`;